<template>
  <b-sidebar
    id="sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    no-close-on-backdrop
    right
    @shown="loadData"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0 text-capitalize">
          {{ isAdd ? `${$t("actions.add")} ${$t("luna.list.title")}` :`${$t("actions.edit")} ${$t("luna.list.title")}` }}
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- List -->
          <validation-provider #default="validationContext" name="list" rules="required">
            <b-form-group
              class="text-capitalize"
              :label="$t('luna.list.name_list')"
              label-for="list"
            >
              <b-form-input
                id="name"
                v-model="itemData.list_data"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Master Account -->
          <validation-provider
            #default="validationContext"
            name="master account"
            rules="required"
          >
            <b-form-group
              :label="$t('luna.master_account.title')"
              label-for="master account"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.id_luna_master_account"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="lunaMasterAccountOptions"
                :reduce="(val) => val.value"
                input-id="master_account"
                :clearable="false"
                :disabled="itemData.id > 0"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Review list -->
          <validation-provider
            #default="validationContext"
            name="review list"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('luna.list.review_list')"
              label-for="review_list"
            >
              <b-form-checkbox
                id="checkbox-1"
                v-model="itemData.check_in"
                name="checkbox-1"
                value="1"
                unchecked-value="0"
              >
              </b-form-checkbox>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Expected similarity -->
          <validation-provider
            #default="validationContext"
            name="expected similarity"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('luna.list.expected_similarity')"
              label-for="expected similarity"
            >
              <b-form-input
                type="number"
                step=".01"
                id="description"
                v-model="itemData.score_similarity"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="alarm level"
            rules="required"
          >
            <b-form-group
              label="alarm level"
              :label-for="$t('luna.list.alarm_level')"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.id_alarms_levels"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="listAlarmLevel"
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="alarm level"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="type list"
            rules="required"
          >
            <b-form-group
              label="type list"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.white_list"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="listsType"
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="white_list"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 text-capitalize"
              type="submit"
            >
              {{ $t("actions.save") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
              class="text-capitalize"
            >
              {{ $t("actions.cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email, positive, required_if } from "@validations";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import { useUtils as useI18nUtils } from "@core/libs/i18n";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isSidebarActive",
    event: "update:is-sidebar-active",
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    itemEdit: {
      type: Object,
      required: false,
    },
    isAdd: {
      type: Boolean,
      required: true,
    },
    lunaMasterAccountOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      positive,
    };
  },
  setup(props, { emit }) {
    const { t } = useI18nUtils();
    const trans = {
      "messages.edit_save.successfully_created": t(
        "messages.edit_save.successfully_created"
      ),
      "messages.edit_save.updated_successfully": t(
        "messages.edit_save.updated_successfully"
      ),
    };
    
    const blankData = {
      list_data: null,
      check_in: null,
      score_similarity: null,
      id_alarms_levels: null,
      id_luna_master_account: null,
      white_list: null
    };
  
    const listAlarmLevel = ref([
      { value: 1, label: "Negro-5" },
      { value: 2, label: "Naranja-2" },
      { value: 3, label: "Amarillo-3" },
      { value: 4, label: "Verde-4" },
      { value: 5, label: "Negro-5" },
      { value: 6, label: "S2" },
      { value: 7, label: "SD" },
      { value: 8, label: "S-SV" },
    ]);

    const listsType = ref([
      { value: 0, label: "Black List" },
      { value: 1, label: "White List" },
    ]);

    const toast = useToast();

    const itemData = ref(JSON.parse(JSON.stringify(blankData)));
    const resetData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankData));
    };

    const onSubmit = () => {
      let dispatch;

      if (props.isAdd) {
        dispatch = store.dispatch("luna_list/add", itemData.value);
      } else {
        const req = {
          id: itemData.value.id,
          params: {
            list_data: itemData.value.list_data,
            check_in: itemData.value.check_in,
            score_similarity: itemData.value.score_similarity,
            id_alarms_levels: itemData.value.id_alarms_levels,
            id_luna_master_account: itemData.value.id_luna_master_account,
            white_list: itemData.value.white_list,
          },
        };

        dispatch = store.dispatch("luna_list/edit", req);
      }

      dispatch
        .then((response) => {
          if (response.success) {
            toast({
              component: ToastificationContent,
              props: {
                title: props.isAdd
                  ? trans["messages.edit_save.successfully_created"]
                  : trans["messages.edit_save.updated_successfully"],
                icon: "CheckIcon",
                variant: "success",
              },
            });
            emit("refetch-data");
            emit("update:is-sidebar-active", false);
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.response.data.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetData);

    const loadData = () => {
      if (!props.isAdd) {
        itemData.value = props.itemEdit;
      }
    };

    return {
      itemData,
      onSubmit,
      listAlarmLevel,
      refFormObserver,
      getValidationState,
      loadData,
      resetForm,
      listsType,
    };
  },
};
</script>