import { ref, computed, watch } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment-timezone";
import store from "@/store";
import ability from "@/libs/acl/ability";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { debounce } from "lodash";

export default function useList() {
  const { t } = useI18nUtils();
  const trans = {
    "messages.error.server_error": t("messages.error.server_error"),
  };
  const refListTable = ref(null);

  const toast = useToast();

  store.dispatch('luna_master_account/getAll')
  const lunaMasterAccountOptions = computed(() => store.getters['luna_master_account/getSelectedItems'])
  const lunaMasterAccountFilter = ref(null)

  const tableColumns = [
    { label: "luna.list.id_luna", key: "id_luna", sortable: true },
    { label: "luna.list.name_list", key: "list_data", sortable: true },
    { label: 'luna.master_account.title', key: 'luna_master_account.name', sortable: true },
    { label: "luna.list.people", key: "count", sortable: true },
    { label: "luna.list.review_list", key: "check_in", sortable: true },
    { label: "luna.list.expected_similarity", key: "score_similarity", sortable: true },
    { label: "luna.list.alarm_level", key: "alarm_level.level", sortable: true },
    { label: "luna.list.white_list", key: "white_list", sortable: true },
  ];
  if (
    ability.can("update", "LunaList") ||
    ability.can("delete", "LunaList")
  ) {
    tableColumns.splice(0, 0, { label: "actions.name", key: "actions" });
  }

  const perPage = ref(10);
  const totalList = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    };
  });

  const fetchList = (ctx, callback) => {
    store
      .dispatch("luna_list/getAll", {
        q: searchQuery.value,
        max: perPage.value,
        "page[n]": currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        luna_master_account: lunaMasterAccountFilter.value
      })
      .then((response) => {
        callback(response.data);
        totalList.value = response.meta.total;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: trans["messages.error.server_error"],
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const formatDateAssigned = (value) => {
    const formattedDate = moment(value).format("DD/MM/YYYY HH:mm:ss");
    return formattedDate;
  };

  const refetchData = () => {
    refListTable.value.refresh();
  };

  watch([lunaMasterAccountFilter, currentPage, perPage, ], () => {
    refetchData();
  });

  watch([ searchQuery ], debounce(() => {
    refetchData()
  }, 500))

  return {
    tableColumns,
    perPage,
    currentPage,
    totalList,
    fetchList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    formatDateAssigned,
    lunaMasterAccountOptions,
    lunaMasterAccountFilter,
    refetchData,
  };
}
