<template>
  <b-card no-body class="m-2">
    <b-card-header class="pb-50">
      <h5 class="text-capitalize">
        {{ $t('filter') }}
      </h5>
      <b-button
        variant="secondary"
        class="float-right text-capitalize"
        @click="$emit('update-list-data')"
        >{{ $t("actions.update") + " " + $t("luna.list.title") }}
      </b-button>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="3"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label class="text-capitalize">{{ $t('luna.master_account.title') }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="lunaMasterAccountFilter"
            :options="lunaMasterAccountOptions"
            class="w-100"
            multiple
            :reduce="val => val.value"
            @input="(val) => $emit('update:lunaMasterAccountFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    vSelect,
  },
  props: {
    lunaMasterAccountOptions: {
      type: Array,
      default: null,
    },
    lunaMasterAccountFilter: {
      type: Array,
      default: null,
    },
  },
};
</script>